<template>
  <div class="main main--internal">
    <div class="page-header__container">
      <h1 class="page-header">Пользовательское соглашение</h1>
    </div>
    <article class="article">
      <div class="article__block">
        <div class="article__content">
          <div class="article__content__text">
            1. Общие положения
            <br>
            <br>
            1.1. Самозанятый Магомедов Арсен Абдуллаевич (далее по тексту — «Судбот») предлагает пользователю сети Интернет (далее — Пользователь) использовать свой сервис по подготовке полного документов, необходимых для обращения в суд (далее по тексту — «Сервис»), на условиях, изложенных в настоящем Пользовательском соглашении (далее — «Соглашение», «ПС»). Соглашение вступает в силу с момента выражения Пользователем согласия с его условиями в порядке, предусмотренном п. 1.4 Соглашения.
            <br><br>
            1.2. Адрес Сервиса в сети Интернет https://sudbot.ru.
            <br><br>
            1.3. Использование Сервиса регулируется настоящим Соглашением и Политикой конфиденциальности. Соглашение может быть изменено Судбот без какого-либо специального уведомления, новая редакция Соглашения вступает в силу с момента ее размещения в сети Интернет по указанному в настоящем абзаце адресу, если иное не предусмотрено новой редакцией Соглашения. Действующая редакция ПС всегда находится на странице по адресу http://www.sudbot.ru/polzovatelskoe-soglashenie.
            <br><br>
            1.4. Начиная использовать Сервис либо пройдя процедуру регистрации в Сервисе, Пользователь считается принявшим условия Соглашения и Политики конфиденциальности в полном объёме без всяких оговорок и исключений. В случае несогласия Пользователя с какими-либо из положений Соглашения, Пользователь не в праве использовать Сервис, информацию и материалы, полученные при его использовании. В случае если Судбот были внесены какие-либо изменения в Соглашение в порядке, предусмотренном пунктом 1.3 Соглашения, с которыми Пользователь не согласен, он обязан прекратить использование Сервиса.
            <br>
            <br>
            2. Регистрация Пользователя. Учетная запись Пользователя
            <br>
            <br>
            2.1. Для того чтобы воспользоваться Сервисом или некоторыми отдельными функциями Сервиса, Пользователю необходимо пройти процедуру регистрации в Сервисе, в результате которой для Пользователя будет создана уникальная учётная запись. Регистрация Пользователя производится также при любом скачивании любого первого документа для обращения в суд.
            <br><br>
            2.2. Для регистрации Пользователь обязуется предоставить достоверную и полную информацию о себе по вопросам, предлагаемым в форме регистрации, и поддерживать эту информацию в актуальном состоянии. Если Пользователь предоставляет неверную информацию или у Судбот есть основания полагать, что предоставленная Пользователем информация неполна или недостоверна, Судбот имеет право по своему усмотрению заблокировать либо удалить учетную запись Пользователя и отказать Пользователю в использовании Сервиса.
            <br><br>
            2.3. Судбот оставляет за собой право в любой момент потребовать от Пользователя подтверждения данных, указанных при регистрации (в частности адрес электронной почты и номер телефона), непредоставление которых, по усмотрению Судбот, может быть приравнено к предоставлению недостоверной информации и повлечь последствия, предусмотренные п. 2.2 Соглашения.
            <br><br>
            2.4. В случае если данные Пользователя, указанные в предоставленных им документах, не соответствуют данным, указанным при регистрации, а также в случае, когда данные, указанные при регистрации, не позволяют идентифицировать Пользователя, Судбот вправе отказать Пользователю в доступе к учетной записи и использованию Сервиса.
            <br><br>
            2.5. Персональная информация Пользователя, содержащаяся в учетной записи Пользователя, а также используемая им при работе с Сервисом, хранится и обрабатывается Судбот в соответствии с условиями Политики обработки персональных данных.
            <br><br>
            2.6. При регистрации Пользователь самостоятельно вводит адрес своей электронной почты, после чего получает на свой адрес реквизиты доступа к его новой учётной записи. При необходимости Пользователь может изменить пароль доступа к Сервису из своей учётной записи.
            <br><br>
            2.7. Пользователь самостоятельно обеспечивает конфиденциальность своего пароля. Пользователь самостоятельно несет ответственность за все действия (а также их последствия) в рамках или с использованием Сервиса под учетной записью Пользователя, включая случаи добровольной передачи Пользователем данных для доступа к учетной записи Пользователя третьим лицам на любых условиях (в том числе по договорам или соглашениям). При этом все действия в рамках или с использованием Сервиса под учетной записью Пользователя считаются произведенными самим Пользователем, за исключением случаев, когда Пользователь в порядке, предусмотренном п. 2.8., уведомил Судбот о несанкционированном доступе к Сервису с использованием учетной записи Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своего пароля.
            <br><br>
            2.8. Пользователь обязан немедленно уведомить Судбот о любом случае несанкционированного (не разрешенного Пользователем) доступа к Сервису с использованием учетной записи Пользователя и/или о любом нарушении (подозрениях о нарушении) конфиденциальности своего пароля. В целях безопасности Пользователь обязан самостоятельно осуществлять безопасное завершение работы под своей учетной записью (кнопка «Выход») по окончании каждой сессии работы с Сервисом. Судбот не отвечает за возможную потерю или порчу данных, а также другие последствия любого характера, которые могут произойти из-за нарушения Пользователем положений этой части Соглашения.
            <br><br>
            2.9. Пользователь не в праве воспроизводить, повторять и копировать, продавать и перепродавать, а также использовать для каких-либо коммерческих целей какие-либо части Сервиса (включая контент, доступный Пользователю посредством Сервиса), или доступ к нему, кроме тех случаев, когда Пользователь получил такое разрешение от Судбот..
            <br><br>
            2.10. Судбот вправе заблокировать или удалить учетную запись Пользователя, а также запретить доступ с использованием какой-либо учетной записи к Сервису и удалить любой контент без объяснения причин, в том числе в случае нарушения Пользователем условий Соглашения или условий иных документов, предусмотренных Соглашением, а также в случае неиспользования сервисов Судбот в течение 6 месяцев.
            <br><br>
            2.11. Удаление учетной записи Пользователя.<br><br>
            2.11.1. Пользователь вправе в любой момент удалить свою учетную запись, воспользовавшись соответствующей функцией в персональном разделе.<br><br>
            2.11.2. Удаление учетной записи Пользователя в Сервисе осуществляется в следующем порядке:<br><br>
            2.11.2.1. учетная запись блокируется на срок один месяц, в течение которого размещенные с ее использованием контент и иные пользовательские данные не удаляются, однако доступ к ним становится невозможен как для Пользователя – владельца учетной записи – так и для других пользователей;<br><br>
            2.11.2.2. если в течение указанного выше срока учетная запись Пользователя будет восстановлена, доступ к указанным данным возобновляется в объёме, существовавшем на момент блокирования (за исключением контента, нарушающего условия Соглашения или иных документов, регулирующих соответствующий сервис);<br><br>
            2.11.2.3. если в течение указанного выше срока учетная запись Пользователя не будет восстановлена, весь контент, размещенный с ее использованием, будет удалён. С этого момента восстановление учетной записи, какой-либо информации, относящейся к ней, а равно доступов к Сервису Судбот с использованием этой учетной записи невозможны.<br><br>

            3. Общие положения об использовании и хранении
            <br>
            <br>
            3.1. Судбот вправе устанавливать ограничения в использовании Сервиса для всех Пользователей либо для отдельных Пользователей (например, в зависимости от места пребывания Пользователя и т.д.), в том числе: максимальное количество обращений к Сервису за указанный период времени, максимальный срок хранения контента/комплектов документов, условия доступа к Сервису и т.д. Судбот может запретить автоматическое обращение к Сервису, а также прекратить прием любой информации, сгенерированной автоматически (например, почтового спама или автозаполнение форм).<br><br>
            3.2. Судбот вправе посылать своим пользователям информационные сообщения, а также информационные сообщения, содержащие информацию о продуктах и услугах партнёров Судбот.<br><br>

            4. Данные Пользователя
            <br>
            <br>
            4.1. Пользователь самостоятельно несет ответственность за корректность данных, вводимых им в процесс использования Сервиса, а также за соответствие вводимых данных требованиям действующего законодательства, включая ответственность перед третьим лицами в случаях, когда Пользователь вводит данные, не имеющие к нему прямого отношения (например, данные третьих лиц), или нарушает права и законные интересы третьих лиц.<br><br>
            4.2. Пользователь признает и соглашается с тем, что Судбот не обязан проверять вводимые Пользователем данные на предмет их корректности.<br><br>
            4.3. Пользователь осознает и соглашается с тем, что Сервис используется вводимые Пользователем данные в полном объёме только для подготовки запрошенных Пользователем документов.<br><br>
            4.4. Пользователь признает и соглашается с тем, что Судбот может передавать нижеуказанные данные своим партнёрам:<br><br>
            • e-mail Пользователя;<br><br>
            • контактный телефон Пользователя (при наличии);<br><br>
            Данные, указанные в настоящем пункте, могут передаваться партнёрам Сервиса, например, для оказания офлайн услуг Пользователю учётной записи.
            <br>
            <br>
            5. Условия использования сервисов Судбот
            <br>
            <br>
            5.1. Пользователь самостоятельно несет ответственность перед третьими лицами за свои действия, связанные с использованием Сервиса, в том числе, если такие действия приведут к нарушению прав и законных интересов третьих лиц, а также за соблюдение законодательства при использовании Сервиса.<br><br>
            5.2. При использовании сервисов Судбот Пользователь не вправе:<br><br>
            5.2.1. выдавать себя за другого человека или представителя организации и/или сообщества без достаточных на то прав;<br><br>
            5.2.2. несанкционированно собирать и хранить персональные данные других лиц;<br><br>
            5.2.3. нарушать нормальную работу Сервиса;<br><br>
            5.2.4. содействовать действиям, направленным на нарушение ограничений и запретов, налагаемых Соглашением;<br><br>
            5.2.5. другим образом нарушать нормы законодательства, в том числе нормы международного права.<br><br>

            6. Исключительные права на содержание сервисов и контент
            <br>
            <br>
            6.1. Все объекты, доступные при помощи Сервиса, в том числе элементы дизайна, текст, графические изображения, базы данных, готовые документы в форматах XLS, XLSX, DOC, DOCX и другие объекты, а также любой контент, размещенный в Сервисе, являются объектами исключительных прав Судбот и других правообладателей.<br><br>
            6.2. Использование Сервиса, а также каких-либо иных элементов Сервиса возможно только в рамках функционала Сервиса. Никакие элементы содержания Сервиса, а также любой контент, размещенный на сервисах Судбот, не могут быть использованы иным образом без предварительного разрешения Судбот. Под использованием подразумеваются, в том числе распространение на любой основе, отображение во фрейме и т.д. Исключение составляют случаи, прямо предусмотренные законодательством РФ или условиями использования Сервиса Судбот.<br><br>
            6.3. Использование Пользователем элементов содержания Сервиса, а также любого контента для личного некоммерческого использования допускается при условии сохранения всех знаков охраны авторского права, смежных прав, товарных знаков, других уведомлений об авторстве, сохранения имени (или псевдонима) автора/наименования правообладателя в неизменном виде, сохранении соответствующего объекта в неизменном виде.<br><br>

            7. Сайты и контент третьих лиц
            <br>
            <br>
            7.1. Сервис Судбот может:<br><br>
            - содержать ссылки на другие сайты в сети Интернет (сайты третьих лиц);<br><br>
            - использовать базы данных третьих лиц (например, базы данных ОКВЭД, КЛАДР, СОУН, СПРО и другие);<br><br>
            - использовать формы документов третьих лиц (заявления по форме, формы платёжных квитанций и другие).<br><br>
            Третьи лица и их контент не проверяются Судбот на соответствие тем или иным требованиям (достоверности, полноты, законности и т.п.). Судбот не несет ответственность за любую информацию, материалы, размещенные на сайтах третьих лиц, к которым Пользователь получает доступ с использованием Сервиса.<br><br>
            7.2. Ссылка (в любой форме) на любой сайт, продукт, услугу, любую информацию коммерческого или некоммерческого характера, размещенная в Сервисе, не является одобрением или рекомендацией данных продуктов (услуг, деятельности) со стороны Судбот.<br><br>

            8. Реклама на Сервисе Судбот
            <br>
            <br>
            8.1. Судбот несет ответственность за рекламу, размещенную на сервисах Судбот, в пределах, установленных законодательством РФ.

            <br>
            <br>
            9. Отсутствие гарантий, ограничение ответственности
            <br>
            <br>
            9.1. Пользователь использует сервисы Судбот на свой собственный риск. Сервис предоставляется «как есть». Судбот не принимает на себя никакой ответственности, в том числе за соответствие Сервиса целям Пользователя.<br><br>
            9.2. Судбот не гарантирует и не несет ответственность за то, что Сервис соответствует/будет соответствовать требованиям Пользователя; что Сервис будет предоставляться непрерывно, быстро, надежно и без ошибок.<br><br>
            9.3. Любые информацию и/или материалы (в том числе загружаемые документы, письма, какие-либо инструкции и руководства к действию и т.д.), доступ к которым Пользователь получает с использованием Сервиса Судбот, имеют справочный и рекомендательный характер и требует перепроверки квалифицированным специалистом в соответствующей области.<br><br>
            9.4. Любые информацию и/или материалы (в том числе загружаемые документы, письма, какие-либо инструкции и руководства к действию и т.д.), доступ к которым Пользователь получает с использованием Сервиса Судбот, Пользователь может использовать на свой собственный страх и риск и самостоятельно несет ответственность за возможные последствия использования указанных информации и/или материалов, в том числе за ущерб, который это может причинить Пользователю или третьим лицам, за потерю данных, времени, денежных средств или любой другой вред.<br><br>
            9.5. Судбот не несет ответственности за любые виды убытков, произошедшие вследствие использования Пользователем Сервиса Судбот или отдельных частей/функций Сервиса.<br><br>
            9.6. При любых обстоятельствах ответственность Судбот в соответствии со статьей 15 Гражданского кодекса России ограничена размером денежных средств, полученных от Пользователя, и возлагается на него при наличии в его действиях вины.<br><br>

            10. Иные положения
            <br>
            <br>
            10.1. Настоящее Соглашение представляет собой договор между Пользователем и Судбот относительно порядка использования Сервиса  и заменяет собой все предыдущие соглашения между Пользователем и Судбот в отношении Сервиса.<br><br>
            10.2. Настоящее Соглашение регулируется и толкуется в соответствии с законодательством Российской Федерации. Вопросы, не урегулированные настоящим Соглашением, подлежат разрешению в соответствии с законодательством Российской Федерации. Все возможные споры, вытекающие из отношений, регулируемых настоящим Соглашением, разрешаются в порядке, установленном действующим законодательством Российской Федерации, по нормам российского права. Везде по тексту настоящего Соглашения, если явно не указано иное, под термином «законодательство» понимается законодательство Российской Федерации.<br><br>
            10.3. Ничто в Соглашении не может пониматься как установление между Пользователем и Судбот агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма либо каких-то иных отношений, прямо не предусмотренных Соглашением.<br><br>
            10.4. Если по тем или иным причинам одно или несколько положений настоящего Соглашения будут признаны недействительными или не имеющими юридической силы, это не оказывает влияния на действительность или применимость остальных положений Соглашения.<br><br>
            10.5. Бездействие со стороны Судбот в случае нарушения Пользователем либо иными пользователями положений Соглашений не лишает Судбот права предпринять соответствующие действия в защиту своих интересов позднее, а также не означает отказа Судбот от своих прав в случае совершения в последующем подобных либо сходных нарушений.<br><br>

            7. Реквизиты самозанятого<br><br>
            ФИО – Магомедов Арсен Абдуллаевич<br><br>
                        Адрес места нахождения: 367018, Дагестан Респ, Махачкала г, ул. Батырая 11, 320.<br><br>
                        Тел. +7 (988) 272-72-73, e-mail: info@mag-law.ru.ru<br><br>
                        ИНН 056205996970
            <br><br>
            Магомедов Арсен Абдуллаевич<br><br>
            Дата: 24 март 2023 г.<br><br>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'AgreementPage'
}
</script>
